body{
  font-family: 'Lato', sans-serif !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.info{
    display: table-cell;
    width: 100vw;
    height: 100vh;
    vertical-align: middle;
}

.info .avatar{
  border: 5px solid;
  border-radius: 100%;
  padding: 2px;
  width: 195px;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
}

.info h2{
  font-weight: 800;
  font-size: 48px;
}

.info .heading h3{
  font-weight: 600;
  text-transform: uppercase;
  font-size: 24px;
  letter-spacing: 1px;
}

.heading span{
  color: #134787;
}

.card{
  border: 0px !important;
}

.heading h3{
  font-weight: 800;
  font-size: 36px;
}

.heading h3 span{
  color: #134787;
}

.icon{
  width: 50px;
}

.card-title{
  font-weight: 800;
  text-transform: uppercase;
  font-size: 22px;
  letter-spacing: 2px;
}

.card-body{
  padding:40px;
}

.skillcard{
  width: 250px;
  height: 275px;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.25);
  color: #000;
  text-align: left;
}

.portfoliocard, .expcard{
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.25);
}

.portfoliocard .card-body{
  padding:30px;
}

.portfoliocard .card-text:last-child
{
  line-height: 2;
}

.tag-label{
    margin-right: 4px;
    margin-bottom: 8px;
    margin-left: 4px;
    padding: 4px 16px;
    border-radius: 20px;
    background-color: #134787;
    -webkit-transition-property: none;
    transition-property: none;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    cursor: default;
    letter-spacing: 1px;
    display: inline-block;
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.25);
}

.skillcard:hover, .portfoliocard:hover, .expcard:hover {
  position: relative;
  animation-delay: 1s;
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}

.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}

.footer h4{
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
}

.footer a, .footer h3{
  font-size: 12px;
  text-decoration: none;
  color: #000;
  font-weight: 600;
  letter-spacing: 2px;
  display: inline-block;
}

.footer a:hover, .footer h3:hover{
  font-size: 12px;
  text-decoration: none;
  color: #134787;
  ;
}


.avatar {
	border-radius: 50%;
	margin: 10px;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

.dot {
  display: inline-block;
  margin-right: 2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #134787;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(23, 75, 143, 0.8);
  animation: pulse 2s infinite;
}
.dot:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(23, 75, 143, 0.8);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(23, 75, 143, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(23, 75, 143, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(23, 75, 143, 0.8);
    box-shadow: 0 0 0 0 rgba(23, 75, 143, 0.8);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(23, 75, 143, 0);
      box-shadow: 0 0 0 10px rgba(23, 75, 143, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(23, 75, 143, 0);
      box-shadow: 0 0 0 0 rgba(23, 75, 143, 0);
  }
}

.footer .heart {
  font-size: 14px;
  color: red;
}



@media (min-width: 576px) {  }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {  }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .skills .col-lg-3{
    width: 20%;
  }
}